import { formatDate, stripTimezoneFromDate } from 'common/utils/date.utils';
import { Text } from '@petrus/ui-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Availablity } from 'graphql/generated/graphql-request';
import Icons from '@petrus/ui-icons';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

type Props = {
  availability?: Availablity[];
  loading: boolean;
};

function LocationFirstAvailable({ availability, loading }: Props) {
  const [noAvailable, setNoAvailable] = useState(false);
  const { t } = useTranslation();

  const findFirstAvailableSlot = useCallback(() => {
    return availability?.find((date) => date.isAvailable)?.slots.find((slot) => slot.isAvailable)
      ?.time;
  }, [availability]);

  useEffect(() => {
    const handleNoAvailable = () => {
      setNoAvailable(true);
    };

    const first = findFirstAvailableSlot();

    if (!first && !loading) {
      const delayTimeout = setTimeout(handleNoAvailable, 1000);
      return () => clearTimeout(delayTimeout);
    }

    setNoAvailable(false);
    return () => {};
  }, [availability, loading, findFirstAvailableSlot]);

  const firstText = useMemo(() => {
    if (noAvailable) {
      return t('domain.booking.step3.no_available');
    }

    const first = findFirstAvailableSlot();

    if (first) {
      return formatDate(stripTimezoneFromDate(first), 'DD. MMM, Kl HH:mm').toLowerCase();
    }

    return t('domain.booking.step3.loading');
  }, [noAvailable, findFirstAvailableSlot, t]);

  return (
    <div className="w-fit mt-2 py-1 px-2 bg-carmine rounded-lg flex gap-1 justify-center items-center">
      <Icons.Clock className="w-3 h-3 p-0" />
      {!noAvailable && (
        <Text className="text-white" size="tiny">
          {t('domain.booking.step3.first_available')}
        </Text>
      )}
      <Text
        className={classNames('text-white align-bottom	', loading && 'animate-pulse')}
        size="tiny"
      >
        {firstText}
      </Text>
    </div>
  );
}

export default LocationFirstAvailable;
