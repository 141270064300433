/* eslint-disable no-restricted-syntax */
import React, { useMemo } from 'react';
import { Card, Heading, SpeiceIcon, Text } from '@petrus/ui-library';
import { AddToCalendarLink } from '@petrus/ui-add-to-calendar';
import Icons from '@petrus/ui-icons';
import { useTranslation } from 'next-i18next';
import { formatDate, stripTimezoneFromDate } from '../../../common/utils/date.utils';
import { useBooking } from '../providers/BookingProvider';

const BookingOverview = () => {
  const { t } = useTranslation();
  const {
    body: { locationId, serviceIds, time, petName, petType, complete },
    data: { locations, serviceGroups },
    actions,
  } = useBooking();
  const location = useMemo(() => {
    !locations.length && actions.getLocations();
    return locations.find((l) => String(l.id) === locationId);
  }, [locationId, locations]);

  const dateString = useMemo(
    () => (time ? formatDate(stripTimezoneFromDate(time), 'DD. MMM, HH:mm') : ''),
    [time],
  );

  const serviceNames = useMemo(() => {
    !serviceGroups.length && actions.getServiceGroups();
    const names = [];
    for (const treatmentGroup of serviceGroups) {
      for (const treatment of treatmentGroup.treatments) {
        if (serviceIds.includes(treatment.provet_id.toString())) {
          names.push(treatment.name); // todo: handle multiple consultation treatments
        }
      }
    }
    return names;
  }, [serviceIds, serviceGroups]);

  return (
    <Card className="text-space flex flex-col gap-2">
      <div className="flex justify-between">
        <Text size="small">{dateString}</Text>
        {complete && (
          <AddToCalendarLink
            ctaText={t('domain.booking.receipt.addToCalendarCta')}
            info={{
              title: 'Time hos Petrus',
              description: serviceNames.join(', '),
              location: location ? location.streetAddress : '',
              startTime: time,
              durationInMin: 30,
            }}
          />
        )}
      </div>
      <Heading as="h4" className="font-bold mb-2">
        {serviceNames.join(', ')}
      </Heading>
      <div className="flex flex-col gap-1">
        {location && (
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-1">
              <Icons.Building className="w-3 h-3" />
              <Text weight="bold" size="small" className="block">
                Petrus {location.name}
              </Text>
            </div>
            <div className="flex items-center gap-1">
              <Icons.Pin className="w-3 h-3" />
              <Text weight="bold" size="small">
                {location.streetAddress}, {location.zipCode} {location.city}
              </Text>
            </div>
          </div>
        )}
        {petName && (
          <div className="flex items-center gap-1">
            <SpeiceIcon speice={petType} className="w-3 h-3 fill-space" />
            <Text weight="bold" size="small">
              {petName}
            </Text>
          </div>
        )}
      </div>
    </Card>
  );
};

export { BookingOverview };
