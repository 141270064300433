import React, { useState } from 'react';
import { AccordionRoot, AccordionCard, For } from '@petrus/ui-library';
import { useTranslation } from 'next-i18next';
import { TreatmentGroups, Treatments } from '../../../graphql/generated/graphql-request';
import { ServiceListItem } from './ServiceListItem';

type Props = {
  onSelect: ({ service, isSelected }: { service: Treatments; isSelected: boolean }) => void;
  selectedServices: string[];
  groups: TreatmentGroups[];
};

const ServicePicker = ({ groups, selectedServices, onSelect }: Props) => {
  const [expandedGroups, setExpandedGroups] = useState<string[]>([groups[0]?.name]);
  const { t } = useTranslation('common');

  const getBadgeInfo = (group: TreatmentGroups): string | undefined => {
    const selectedServicesFromGroup = group.treatments.filter(({ provet_id }) =>
      selectedServices.includes(provet_id),
    ).length;

    return (
      selectedServicesFromGroup > 0 &&
      t('domain.booking.step2.selected', { count: selectedServicesFromGroup })
    );
  };

  return (
    <AccordionRoot
      type="multiple"
      defaultValue={[groups[0]?.name]}
      asChild
      onValueChange={(val) => setExpandedGroups(val)}
    >
      <div className="flex flex-col gap-2">
        <For<TreatmentGroups>
          each={groups}
          render={(group) => {
            if (group.treatments.length < 1) return;
            return (
              <AccordionCard
                value={group.name}
                heading={group.name}
                badgeInfo={getBadgeInfo(group)}
                isOpen={expandedGroups.includes(group.name)}
              >
                <div className="flex flex-col gap-2">
                  <For<Treatments>
                    each={group.treatments}
                    render={(service) => (
                      <ServiceListItem
                        onSelect={onSelect}
                        selectedServices={selectedServices}
                        service={service}
                      />
                    )}
                  />
                </div>
              </AccordionCard>
            );
          }}
        />
      </div>
    </AccordionRoot>
  );
};

export { ServicePicker };
export type { Props };
