import { useAnimals } from 'domain/animal/providers/AnimalProvider';
import { AnimalTypePicker } from 'domain/animal/components/AnimalTypePicker';
import { AnimalForm } from 'domain/animal/components/AnimalForm';
import { LoginLink } from 'domain/users/components/LoginLink';
import React, { useEffect, useState } from 'react';
import { Heading, Text, Center, Spinner, Alert } from '@petrus/ui-library';

import { Link } from 'common/components/Link';
import { useTranslation } from 'next-i18next';
import posthog from 'posthog-js';
import { AnimalGendersEnum, AnimalSpeciesEnum } from 'graphql/generated/graphql-request';
import { constants } from '../../../config';
import { AnimalPicker } from '../../animal/components/AnimalPicker';
import { useSession } from '../../auth/providers/SessionProvider';
import { useBooking } from '../providers/BookingProvider';
import { BookingRequestInfo } from '../types';

const BookAnimal = () => {
  const { t } = useTranslation();
  const [adding, setAdding] = useState(false);
  const { body, actions } = useBooking();
  const { checkLogin } = useSession();
  const { get, create } = useAnimals();

  useEffect(() => {
    posthog.capture(`bookingstep_animal`);
  }, []);

  if (checkLogin?.isLoading) {
    return (
      <Center>
        <Spinner className="w-16 h-16 fill-carmine" />
      </Center>
    );
  }

  const setAnimal = ({
    petId,
    petName,
    petGender,
    petType,
    petDob,
    petWeight,
  }: Partial<BookingRequestInfo>) => {
    posthog.capture(petId ? 'Animal selected' : 'Animal type selected', {
      petName,
      petGender,
      petType,
      petDob,
      petWeight,
    });
    actions.onStepSubmit({
      petId,
      petName,
      petGender,
      petType,
      petDob,
      petWeight,
    });
  };

  if (!body.userId) {
    return (
      <div className="flex flex-col gap-3 text-carmine">
        <Heading as="h2">{t('domain.booking.step1.animalTypeSelect')}</Heading>
        <Text className="block mb-4">
          {t('domain.booking.step1.cubsOrder')}
          <Link href={constants.orderForCubsLink}>{t('global.clickHere')}</Link>
        </Text>
        <AnimalTypePicker
          onSelect={(species) => setAnimal({ petType: species })}
          selectedType={body?.petType}
        />
        <LoginLink className="mt-2" />
      </div>
    );
  }

  if (adding) {
    return (
      <div className="flex flex-col gap-3 text-carmine">
        <Heading as="h2">{t('domain.booking.step1.animalInfo')}</Heading>
        <AnimalForm
          submitText={t('domain.booking.step1.cta')} // opprett dyr
          onAbort={() => setAdding(false)}
          onSubmit={async ({ name, type, gender, dob, weight }) => {
            posthog.capture('Animal added');
            create.mutate(
              { name, type, gender, dob, weight },
              {
                onSuccess: (animal) =>
                  setAnimal({
                    petName: name,
                    petGender: gender,
                    petType: type,
                    petDob: dob,
                    petWeight: weight,
                    petId: animal.id,
                  }),
              },
            );
          }}
          defaultValues={{
            name: body.petName,
            gender: body.petGender as AnimalGendersEnum,
            type: body.petType as AnimalSpeciesEnum,
            dob: body.petDob,
            weight: body.petWeight,
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <Heading as="h2" className="text-carmine pb-4">
        {t('domain.booking.step1.animalSelect')}
      </Heading>
      <div className="text-carmine py-2">
        <Text>
          {t('domain.booking.step1.cubsOrder')}
          <Link href={constants.orderForCubsLink}>{t('global.clickHere')}</Link>
        </Text>
      </div>
      <div className="pb-2">
        <Alert type="danger" hasError={get.isError}>
          {get.error as string}
        </Alert>
      </div>
      {get.isLoading ? (
        <Center>
          <Spinner className="w-16 h-16 fill-carmine" />
        </Center>
      ) : (
        <AnimalPicker
          animals={get.data as any}
          selectedAnimal={body?.petId}
          onAdd={() => setAdding(true)}
          onClick={(animal) =>
            setAnimal({
              petName: animal.name,
              petGender: animal.gender,
              petType: animal.type,
              petId: animal.id,
            })
          }
        />
      )}
    </div>
  );
};

export { BookAnimal };
