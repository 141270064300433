import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import {
  FormProvider,
  useForm,
  Heading,
  Textarea,
  Button,
  Alert,
  Dialog,
} from '@petrus/ui-library';

import { Link } from 'common/components/Link';
import { config } from 'config';
import { useBooking } from '../providers/BookingProvider';
import { BookingOverview } from './BookingOverview';
import { BookAnimal } from './BookAnimal';

const ConfirmBooking = () => {
  const { t } = useTranslation();
  const { body, data, actions } = useBooking();
  const methods = useForm({ mode: 'onChange' });

  const needsDisclaimer = useMemo<boolean>(
    () => body.serviceIds.some((id) => config.treatmentOverwrite.cleaning.ids.includes(id)),
    [body.serviceIds],
  );

  const noteRequired = useMemo<boolean>(
    () => body.serviceIds.some((id) => config.treatmentOverwrite.noteRequired.ids.includes(id)),
    [body.serviceIds],
  );

  if (!body.userId) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 text-carmine">
      <Dialog isOpen={!body.petId} withHeader={false}>
        <BookAnimal />
      </Dialog>
      <Heading as="h2">{t('domain.booking.step7.heading')}</Heading>
      <BookingOverview />
      <div className="pb-2">
        <Alert type="danger" hasError={data.meta.booking.error}>
          {data.meta.booking.error}
        </Alert>
      </div>
      <Alert hasError={needsDisclaimer} type="info">
        {t('domain.booking.step7.disclaimer')}
        <Link className="contents" href={`tel:${config.tlf}`}>
          {config.tlf}
        </Link>
      </Alert>
      <FormProvider {...methods}>
        <form
          className="space-y-4"
          onSubmit={methods.handleSubmit((v) => actions.createBooking(v as { note: string }))}
        >
          <Textarea
            label={t('domain.booking.step6.noteLabel')}
            placeholder={
              noteRequired
                ? t('domain.booking.step6.requiredNotePlaceholder')
                : t('domain.booking.step6.notePlaceholder')
            }
            id="note"
            required={noteRequired}
          />
          <div className="flex justify-end pt-2">
            <Button
              loading={data.meta.booking.loading}
              type="submit"
              disabled={!methods.formState.isValid}
            >
              {t('domain.booking.step7.cta')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export { ConfirmBooking };
