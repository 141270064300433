import React from 'react';
import { Heading, CardButton } from '@petrus/ui-library';
import { AnimalSpeciesEnum } from 'graphql/generated/graphql-request';
import { useTranslation } from 'next-i18next';
import { Species } from 'types';
import { SpecieAvatar } from './SpecieAvatar';

type Props = {
  selectedType: Species;
  onSelect: (species: AnimalSpeciesEnum) => void;
};

const AnimalTypePicker = ({ selectedType, onSelect }: Props) => {
  const animalSpecies = [AnimalSpeciesEnum.DOG, AnimalSpeciesEnum.CAT];
  return (
    <div className="flex flex-col gap-2">
      {animalSpecies.map((species) => (
        <AnimalTypeButton
          key={species}
          species={species}
          onSelect={onSelect}
          isSelected={selectedType === species}
        />
      ))}
    </div>
  );
};

const AnimalTypeButton = ({
  species,
  onSelect,
  isSelected,
}: {
  species: AnimalSpeciesEnum;
  onSelect;
  isSelected: boolean;
}) => {
  const { t } = useTranslation('common');
  return (
    <CardButton
      className="text-left p-3 leading-5 w-full relative"
      onClick={() => onSelect(species)}
      useArrow
      id={species}
      selected={isSelected}
    >
      <div className="flex gap-4 items-center">
        <SpecieAvatar speice={species} />
        <Heading as="h4">{t(`domain.animal.species.${species}`)}</Heading>
      </div>
    </CardButton>
  );
};

export { AnimalTypePicker };
export type { Props };
