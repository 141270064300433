import React from 'react';
import { useTranslation } from 'next-i18next';
import { Heading, Button, Text, Callout } from '@petrus/ui-library';

import { config } from 'config';
import { Link } from 'common/components/Link';
import { AnimalFragment } from 'graphql/generated/graphql-request';
import { AnimalCardButton } from './AnimalCardButton';

type Props = {
  animals: AnimalFragment[];
  selectedAnimal?: string;
  onClick: (a: AnimalFragment) => void;
  onAdd: () => void;
};

const AnimalPicker = ({ animals, onAdd, onClick, selectedAnimal }: Props) => {
  const { t } = useTranslation('common');

  if (!animals) return null;

  return (
    <div className="grid grid-cols-1 gap-2">
      {animals.length === 0 ? (
        <Callout>
          <div className="text-carmine md:text-center">
            <Heading as="h4" className="md:text-center pb-2">
              {t('domain.animal.empty.heading')}
            </Heading>
            <Text>
              {t('domain.animal.empty.description')}{' '}
              <Link href={`mailto:${config.mailUrl}`}>{config.mailUrl}</Link>
            </Text>
          </div>
        </Callout>
      ) : (
        animals.map((animal) => (
          <AnimalCardButton
            key={animal.id}
            selected={selectedAnimal === animal.id}
            animal={animal}
            onClick={onClick}
          />
        ))
      )}
      <Button
        block
        size="micro"
        look="unstyled"
        onClick={onAdd}
        className="focus:outline-none focus:ring focus:ring-pink rounded-lg border-2 border-dashed border-carmine p-6 decoration-carmine hover:underline"
      >
        <Text className="text-carmine">{t('domain.animal.cta.add')}</Text>
      </Button>
    </div>
  );
};

export { AnimalPicker };
export type { Props };
