import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Heading } from '@petrus/ui-library';

import posthog from 'posthog-js';
import { AvailabilityPicker } from '../../availability/components/AvailabilityPicker';
import { useBooking } from '../providers/BookingProvider';

const BookTime = () => {
  const { t } = useTranslation();
  const { body, data, actions } = useBooking();

  useEffect(() => {
    if (
      data.meta.availability.loading ||
      data.availability.some(
        (locationAvailability) => locationAvailability.locationId === body.locationId,
      )
    )
      return;
    actions.getAvailability();
  }, [body.serviceIds, body.locationId]);

  const { locationAvailability, hasLoaded } = useMemo(() => {
    const availability = data.availability.find(
      (locationAvailability) => locationAvailability.locationId === body.locationId,
    );
    return { locationAvailability: availability?.availability || [], hasLoaded: !!availability };
  }, [JSON.stringify(data.availability)]);

  const onTimeSlect = (time?: string) => {
    if (time) {
      posthog.capture('Time selected', { time });
    }
    actions.onStepSubmit({ time });
  };

  const TimeHeading = () => (
    <Heading as="h2" className="text-carmine mb-4">
      {t('domain.booking.step4.heading')}
    </Heading>
  );

  if (!body.locationId) return <TimeHeading />;
  return (
    <div>
      <TimeHeading />
      <AvailabilityPicker
        loading={data.meta.availability.loading || !hasLoaded}
        error={data.meta.availability.error}
        availabilityList={locationAvailability}
        onSelect={onTimeSlect}
        selectedTime={body.time}
      />
    </div>
  );
};

export { BookTime };
