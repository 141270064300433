import { AnimalForm } from 'domain/animal/components/AnimalForm';
import { Alert, Heading, Textarea } from '@petrus/ui-library';
import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { AnimalGendersEnum, AnimalSpeciesEnum } from 'graphql/generated/graphql-request';
import { config } from 'config';
import { Link } from 'common/components/Link';
import * as yup from 'yup';
import { note } from 'common/helpers/yup/validation';
import { useBooking } from '../providers/BookingProvider';
import { BookingOverview } from './BookingOverview';

function BookingAnimalInfo() {
  const { t } = useTranslation();
  const { body, data, actions } = useBooking();

  const needsDisclaimer = useMemo<boolean>(
    () => body.serviceIds.some((id) => config.treatmentOverwrite.cleaning.ids.includes(id)),
    [body.serviceIds],
  );

  const noteRequired = useMemo<boolean>(
    () => body.serviceIds.some((id) => config.treatmentOverwrite.noteRequired.ids.includes(id)),
    [body.serviceIds],
  );
  // TODO: loading state on createBooking
  return (
    <div className="flex flex-col gap-4 text-carmine">
      <Heading as="h2">{t('domain.booking.step6.heading')}</Heading>
      <BookingOverview />
      <Alert hasError={needsDisclaimer} type="info">
        {t('domain.booking.step7.disclaimer')}
        <Link className="contents" href={`tel:${config.tlf}`}>
          {config.tlf}
        </Link>
      </Alert>
      <div className="mt-4">
        <AnimalForm
          loading={data.meta.booking.loading}
          error={data.meta.booking.error}
          submitText={t('domain.booking.step6.cta')}
          onSubmit={actions.createBooking}
          defaultValues={{
            name: body.petName,
            gender: body.petGender as AnimalGendersEnum,
            type: body.petType as AnimalSpeciesEnum,
            dob: body.petDob,
            weight: body.petWeight,
            note: '',
          }}
          additionalSchema={noteRequired && yup.object().shape({ note })}
        >
          <Textarea
            id="note"
            label={t('domain.booking.step6.noteLabel')}
            placeholder={
              noteRequired
                ? t('domain.booking.step6.requiredNotePlaceholder')
                : t('domain.booking.step6.notePlaceholder')
            }
            required={noteRequired}
          />
        </AnimalForm>
      </div>
    </div>
  );
}

export default BookingAnimalInfo;
