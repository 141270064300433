import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

function AnimatedCheckmark() {
  return (
    <Player
      autoplay
      src={checkmark_thin}
      keepLastFrame
      style={{
        zIndex: 'initial',
        height: '300px',
        width: '300px',
        marginTop: '-40px',
        marginBottom: '-80px',
      }}
    />
  );
}

const checkmark_thin = {
  nm: 'check',
  ddd: 0,
  h: 1200,
  w: 1200,
  meta: { g: '@lottiefiles/toolkit-js 0.33.2' },
  layers: [
    {
      ty: 4,
      nm: 'Shape Layer 3',
      sr: 1,
      st: 13,
      op: 50,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [97.1, 97.1, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [596, 590, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [465.703, 465.703], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 2,
              ml: 1,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 43, ix: 5 },
              c: { a: 0, k: [0.5608, 0, 0.0667], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [99.105, 99.105], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-7, 11], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'tm',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Filter - Trim',
          nm: 'Trim Paths 1',
          ix: 2,
          e: {
            a: 1,
            k: [
              { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 26 },
              { s: [83], t: 36 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 85, ix: 3 },
          s: { a: 0, k: 0, ix: 1 },
          m: 1,
        },
      ],
      ind: 1,
    },
    {
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      st: 13,
      op: 50,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [97.1, 97.1, 100], t: 14 },
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [90, 90, 100], t: 17 },
            { s: [97.1, 97.1, 100], t: 20 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [596, 589, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [465.703, 465.703], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 2,
              ml: 1,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 43, ix: 5 },
              c: { a: 0, k: [0.9255, 0.9255, 0.9255], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [99.105, 99.105], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-7, 11], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'tm',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Filter - Trim',
          nm: 'Trim Paths 1',
          ix: 2,
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 85, ix: 3 },
          s: {
            a: 1,
            k: [
              { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 17 },
              { s: [100], t: 27 },
            ],
            ix: 1,
          },
          m: 1,
        },
      ],
      ind: 2,
    },
    {
      ty: 4,
      nm: 'Shape Layer 4',
      sr: 1,
      st: 0,
      op: 50,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [600, 600, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 1',
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-93.5, -37],
                    [-11.5, 45],
                    [250.5, -216.5],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 2,
              ml: 1,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 43, ix: 5 },
              c: { a: 0, k: [0.5608, 0, 0.0667], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'tm',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Filter - Trim',
          nm: 'Trim Paths 1',
          ix: 2,
          e: {
            a: 1,
            k: [
              { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 29 },
              { s: [100], t: 36 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          s: { a: 0, k: 0, ix: 1 },
          m: 1,
        },
      ],
      ind: 3,
    },
  ],
  v: '5.1.16',
  fr: 23,
  op: 50,
  ip: 0,
  assets: [],
};

export default AnimatedCheckmark;
