import React from 'react';
import { useTranslation } from 'next-i18next';
import { Heading, Center, Alert, Spinner } from '@petrus/ui-library';

import posthog from 'posthog-js';
import { LocationPicker } from '../../locations/components/LocationPicker';
import { useBooking } from '../providers/BookingProvider';

const BookLocation = () => {
  const { t } = useTranslation();
  const { body, data, actions } = useBooking();

  const onLocationSelect = (id: string) => {
    posthog.capture('Location selected', { locationId: id });
    actions.onStepSubmit({ locationId: id });
  };

  return (
    <div>
      <Heading as="h2" className="text-carmine mb-4">
        {t('domain.booking.step3.heading')}
      </Heading>
      <div className="pb-2">
        <Alert type="danger" hasError={data.meta.locations.error}>
          {data.meta.locations.error}
        </Alert>
      </div>
      {data.meta.locations.loading ? (
        <Center>
          <Spinner className="w-16 h-16 fill-carmine" />
        </Center>
      ) : (
        <LocationPicker
          locations={data.locations}
          availability={data.availability}
          isAvailabilityLoading={data.meta.availability.loading}
          selectedLocation={body.locationId}
          onSelect={onLocationSelect}
        />
      )}
    </div>
  );
};

export { BookLocation };
