import React, { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import * as yup from 'yup';
import { useYupResolver } from '@petrus/ui-hooks';
import { FormProvider, useForm, Input, Button, Text, Alert } from '@petrus/ui-library';

import { otp } from 'common/helpers/yup/validation';
import { useSession } from '../providers/SessionProvider';
import { ResendTimer } from '../components/ResendTimer';

type FormValues = {
  otp: string;
};

type Props = {
  submitText?: string;
  returnTo?: string;
  defaultValues?: FormValues;
};

const schema = yup.object().shape({ otp });

const OTPForm = ({ submitText, defaultValues, returnTo }: Props) => {
  const { t } = useTranslation('common');
  const resolver = useYupResolver(schema);
  const methods = useForm({ defaultValues, resolver, mode: 'onChange' });
  const {
    formState: { errors },
  } = methods;
  const { resendCode, login, phone, sendTime } = useSession();

  const onSubmit = useCallback(
    ({ otp }: FormValues) => login.mutate({ otp, returnTo }),
    [returnTo],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Text className="block">
          {t('domain.auth.otp.senderInfo')} <Text weight="bold">{phone}</Text>
        </Text>
        <Alert hasError={login.isError} type="danger">
          {t(`errors.otp.${login.error}`)}
        </Alert>
        <Input
          id="otp"
          autoComplete="one-time-code"
          placeholder="1234"
          inputMode="numeric"
          error={t(errors.otp?.message.toString())}
          label={t('domain.auth.otp.label')}
          ghostLabel
          autofocus
        />
        <div className="mt-4">
          <ResendTimer
            sendTime={sendTime}
            onResendRequest={() => resendCode.mutate({})}
            loading={resendCode.isLoading}
          />
          <Button
            block
            disabled={!methods.formState.isValid}
            loading={login.isLoading}
            type="submit"
          >
            {submitText}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export { OTPForm, schema };
export type { Props, FormValues };
