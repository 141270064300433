import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { useRouter } from 'next/router';
import { useBooking } from '../providers/BookingProvider';
import { useSession } from '../../auth/providers/SessionProvider';
import { Stepper } from '../components/Stepper';
import { BookAnimal } from './BookAnimal';
import { BookTreatment } from './BookTreatment';
import { BookLocation } from './BookLocation';
import { BookTime } from './BookTime';
import { ConfirmBooking } from './ConfirmBooking';
import { BookingReceipt } from './BookingReceipt';
import CustomerInfo from './BookingCustomerInfo';
import BookingAnimalInfo from './BookingAnimalInfo';

const BookingWizard = () => {
  const { session } = useSession();
  const router = useRouter();
  const { data, body, currentStep, actions } = useBooking();

  useEffect(() => {
    if (session && !body.userId) {
      actions.onStepSubmit({ userId: session.id });
    }
  }, [router, session, body, actions]);

  if (body.complete) {
    return <BookingReceipt />;
  }

  return (
    <div>
      <div className="pb-2 md:py-4">
        <Stepper
          currentIndex={data.currentIndex}
          goBack={actions.onPreviousButtonClick}
          numberOfSteps={data.bookingSteps.length}
        />
      </div>
      <div className="pt-2 pb-4 lg:mt-0 w-full">
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={data.currentIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4, delay: 0 }}
            exit={{
              opacity: 0,
              transition: { duration: 0.2, delay: 0 },
            }}
          >
            {currentStep.step === 'animal' && <BookAnimal />}
            {currentStep.step === 'treatment' && <BookTreatment />}
            {currentStep.step === 'location' && <BookLocation />}
            {currentStep.step === 'time' && <BookTime />}
            {currentStep.step === 'customerInfo' && <CustomerInfo />}
            {currentStep.step === 'animalInfo' && <BookingAnimalInfo />}
            {currentStep.step === 'confirmation' && <ConfirmBooking />}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export { BookingWizard };
