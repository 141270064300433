import { useSession } from 'domain/auth/providers/SessionProvider';
import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import Icons from '@petrus/ui-icons';
import { Heading, Text } from '@petrus/ui-library';

import { LinkButton } from 'common/components/LinkButton';
import { stripTimezoneFromDate, getNorwegianFullDate } from 'common/utils/date.utils';
import { Treatments } from 'graphql/generated/graphql-request';
import AnimatedCheckmark from 'common/components/animated/Checkmark';
import { useBooking } from '../providers/BookingProvider';
import { BookingOverview } from './BookingOverview';

const BookingReceipt = () => {
  const { t } = useTranslation();
  const { body, data, actions } = useBooking();
  const { session } = useSession();

  const isAuthenticated = useMemo(() => !!session, [session]);

  const location = useMemo(() => {
    !data.locations.length && actions.getLocations();
    return data.locations.find((l) => String(l.id) === body.locationId);
  }, [body.locationId, data.locations]);

  const services = useMemo<Treatments[]>(() => {
    !data.serviceGroups.length && actions.getServiceGroups();
    return body.serviceIds.reduce((prev, current) => {
      const group = data.serviceGroups.find((serviceGroup) =>
        serviceGroup.treatments.some((treatment) => treatment.provet_id === current),
      );

      if (!group) {
        return prev;
      }

      const service = group.treatments.find((treat) => treat.provet_id === current);

      if (service) {
        prev.push(service);
      }

      return prev;
    }, []);
  }, [body.serviceIds, data.serviceGroups]);

  const hasInstructions = useMemo<boolean>(
    () => services.some((service) => !!service.instructions),
    [services],
  );

  const dateString = useMemo(
    () => getNorwegianFullDate(stripTimezoneFromDate(body.time)).toLowerCase(),
    [body.time],
  );

  return (
    <div className="flex flex-col gap-4 text-carmine">
      <AnimatedCheckmark />
      <Heading as="h2" className="mb-4 text-center">
        {t('domain.booking.receipt.heading')}
      </Heading>
      <BookingOverview />
      <Text>
        {t('domain.booking.receipt.date', {
          locationDate: `Petrus ${location?.name}, ` + dateString,
        })}
      </Text>
      {hasInstructions ? (
        <div className="flex flex-col py-3 gap-3">
          <Heading as="h4">Til informasjon:</Heading>
          <ul className="flex flex-col gap-3">
            {services.map((service) =>
              service.instructions ? (
                <li key={service.id}>
                  <div className="space-y-1">
                    <Text size="base" weight="bold" className="block">
                      {service.display_name}
                    </Text>
                    <Text size="small" className="block">
                      {service.instructions}
                    </Text>
                  </div>
                </li>
              ) : null,
            )}
          </ul>
        </div>
      ) : null}
      <div className="flex flex-row justify-between mt-4">
        <div>
          {!isAuthenticated && (
            <Text className="block">{t('domain.booking.receipt.myPage.guestInfo')}</Text>
          )}
          <Text>{t('domain.booking.receipt.myPage.head')}</Text>
          <LinkButton className="block mt-2" href="/min-side">
            {t('domain.booking.receipt.myPage.cta')}
          </LinkButton>
        </div>

        <Icons.PawsPath className="w-32 fill-carmine mt-2 md:mt-12" />
      </div>
    </div>
  );
};

export { BookingReceipt };
