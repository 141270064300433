import { LocationAvailability } from 'domain/availability/types';
import React from 'react';
import { Heading, CardButton, Callout, Text } from '@petrus/ui-library';

import { LocationFragment } from 'graphql/generated/graphql-request';
import { config } from 'config';
import classNames from 'classnames';
import { LocationAddress } from './LocationAddress';
import LocationFirstAvailable from './LocationFirstAvailable';
import LocationClosedIndicator from './LocationClosedIndicator';

type Props = {
  locations: LocationFragment[];
  availability: LocationAvailability[];
  isAvailabilityLoading: boolean;
  selectedLocation?: string;
  onSelect: (id: string) => void;
};

const LocationPicker = ({
  locations,
  availability,
  isAvailabilityLoading,
  selectedLocation,
  onSelect,
}: Props) => {
  return (
    <div className="flex flex-col gap-2">
      {locations.length === 0 ? (
        <Callout>
          <div className="space-y-4 text-carmine">
            <Heading as="h4" className="block">
              Valgt behandling er dessverre ikke tilgjengelig på noen av våre klinikker for
              øyeblikket.
            </Heading>
            <Text className="block">
              Hvis du har valgt flere behandlinger, vær oppmerksom på at de kanskje ikke kan
              gjennomføres på samme klinikk. Vennligst velg en annen kombinasjon eller ring oss på{' '}
              <a className="cursor-pointer hover:underline" href={`tlf:${config.tlf}`}>
                {config.tlf}
              </a>{' '}
              eller mail oss på{' '}
              <a className="cursor-pointer hover:underline" href={`mailto:${config.mailUrl}`}>
                {config.mailUrl}
              </a>
              , slik at vi kan hjelpe deg å gjennomføre bookingen.
            </Text>
          </div>
        </Callout>
      ) : (
        <>
          {locations.map((location) => {
            const isClosed = config.clinicOverwrite.closed.includes(location.id.toString());
            const locationAvailabilityResponse = availability.find(
              (loacationAvailability) =>
                loacationAvailability.locationId === location.id.toString(),
            );
            const locationAvailability = locationAvailabilityResponse?.availability || [];
            return (
              <CardButton
                key={location.provetId}
                id={location.provetId}
                className={classNames(
                  'text-left px-5 pt-5 pb-3 gap-1 w-full relative',
                  isClosed && 'opacity-80',
                )}
                selected={location.provetId === selectedLocation}
                onClick={() => onSelect(location.provetId)}
                disabled={isClosed}
                useArrow={!isClosed}
              >
                <Heading as="h4">{location.name}</Heading>
                <LocationAddress location={location} />
                {isClosed ? (
                  <LocationClosedIndicator />
                ) : (
                  <LocationFirstAvailable
                    availability={locationAvailability}
                    loading={isAvailabilityLoading || !locationAvailabilityResponse}
                  />
                )}
              </CardButton>
            );
          })}
        </>
      )}
    </div>
  );
};

export { LocationPicker };
export type { Props };
