import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Icons from '@petrus/ui-icons';
import {
  Button,
  Btn,
  Card,
  Text,
  Heading,
  Center,
  Alert,
  Spinner,
  Search,
} from '@petrus/ui-library';

import posthog from 'posthog-js';
import { TreatmentGroups, Treatments } from 'graphql/generated/graphql-request';
import { config } from '../../../config';
import { ServicePicker } from '../../services/components/ServicePicker';
import { useBooking } from '../providers/BookingProvider';
import { Link } from '../../../common/components/Link';

const BookTreatment = () => {
  const { t } = useTranslation();
  const { body, data, actions } = useBooking();
  const [selectedServices, setSelectedServices] = useState<string[]>([]);

  useEffect(() => {
    setSelectedServices(body.serviceIds || []);
  }, []);

  useEffect(() => {
    actions.getServiceGroups();
  }, [body.petType]);

  const onSelect = useCallback(
    ({
      service,
      isSelected,
    }: {
      service: Partial<Pick<Treatments, 'provet_id' | 'name'>>;
      isSelected: boolean;
    }) => {
      const serviceId = service.provet_id;
      if (isSelected) {
        setSelectedServices((prev) => {
          const newSelectedServices = [...prev, serviceId];
          posthog.capture('Service selected', {
            serviceId,
            serviceName: service.name,
            selectedServices: newSelectedServices,
          });
          return newSelectedServices;
        });
      } else {
        setSelectedServices((prev) => {
          const newSelectedServices = prev.filter((service) => service !== serviceId);
          posthog.capture('Service removed', {
            serviceId,
            serviceName: service.name,
            selectedServices: newSelectedServices,
          });
          return newSelectedServices;
        });
      }
    },
    [setSelectedServices],
  );

  return (
    <div>
      <div className="flex md:flex-row justify-between mb-4">
        <Heading as="h2" className="text-carmine">
          {t('domain.booking.step2.heading')}
        </Heading>
        <Search<Treatments>
          data={data.serviceGroups.reduce((acc: Treatments[], group: TreatmentGroups) => {
            return acc.concat(group.treatments);
          }, [])}
          searchKeys={['name', 'display_name', 'description']}
          displayKeys={['display_name']}
          onSelect={(treatment) =>
            onSelect({
              service: treatment,
              isSelected: !selectedServices.includes(treatment.provet_id),
            })
          }
        />
      </div>
      <Alert type="danger" hasError={data.meta.serviceGroups.error} className="mb-2">
        {data.meta.serviceGroups.error}
      </Alert>
      {data.meta.serviceGroups.loading ? (
        <Center>
          <Spinner className="w-16 h-16 fill-carmine" />
        </Center>
      ) : (
        <>
          {config.reseptOverwrite.isActive && (
            <Card className="mb-4 p-4 border border-carmine bg-transparent text-carmine flex flex-col gap-2">
              <div className="flex gap-2 items-center text-carmine stroke-carmine">
                <Icons.InfoCircle className="w-5 h-5" />
                <Heading as="h4">{config.reseptOverwrite.title}</Heading>
              </div>
              <Text size="small" className="pb-2">
                {config.reseptOverwrite.description}
              </Text>
              <div className="flex flex-col sm:flex-row justify-end gap-2">
                <Btn
                  size="sm"
                  intent="simple"
                  className="flex"
                  onClick={() => {
                    const { konsultasjonId, title } = config.reseptOverwrite;
                    onSelect({
                      service: {
                        provet_id: konsultasjonId,
                        name: title,
                      },
                      isSelected: !selectedServices.includes(konsultasjonId),
                    });
                  }}
                >
                  Bestill konsultasjon
                </Btn>
                <Btn size="sm" className="stroke-white flex gap-2" asChild>
                  <Link href={config.reseptOverwrite.link}>
                    <span>{config.reseptOverwrite.cta}</span>
                    <Icons.Newscreen className="w-4 h-4" />
                  </Link>
                </Btn>
              </div>
            </Card>
          )}
          <ServicePicker
            groups={data.serviceGroups}
            selectedServices={selectedServices}
            onSelect={onSelect}
          />
          {selectedServices.length > 0 && (
            <div className="flex justify-end pb-2 pt-4 sticky bottom-0 bg-gradient-to-b	from-transparent to-blush/70">
              <Button
                id="selectTreatments"
                onClick={() => actions.onStepSubmit({ serviceIds: selectedServices })}
              >
                {t('domain.booking.step2.cta')}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export { BookTreatment };
