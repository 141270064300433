/* eslint-disable @typescript-eslint/no-explicit-any */
import { i18n } from 'next-i18next';

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import nextI18NextConfig from '../../next-i18next.config.js';

export const getStaticProps = async (context) => {
  if (process.env.NODE_ENV === 'development') {
    await i18n?.reloadResources();
  }

  return {
    props: {
      ...(await serverSideTranslations(context.locale, ['common'], nextI18NextConfig as any)),
    },
    notFound: false,
    revalidate: 60,
  };
};

export { default } from 'app/booking/page';
