import { UserForm } from 'domain/users/components/UserForm';
import { LoginForm } from 'domain/auth/containers/LoginForm';
import { useSession } from 'domain/auth/providers/SessionProvider';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Heading } from '@petrus/ui-library';
import { useRouter } from 'next/router';
import { useSearchParams } from 'next/navigation';
import posthog from 'posthog-js';
import { getPhoneNumberFormatted } from '@askeladden/phonenumber';
import { useBooking } from '../providers/BookingProvider';
import { BookingOverview } from './BookingOverview';

const CustomerInfo = () => {
  const { t } = useTranslation();
  const { actions } = useBooking();
  const { session, phone, sendCode } = useSession();
  const { push, asPath } = useRouter();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (session) {
      const { id, ...rest } = session;
      push(`${asPath}&userId=${id}`);
      posthog.identify(id, { ...rest });
    }

    if ((sendCode.error as any)?.message === 'no_signup') {
      push(`${asPath}&phone=${phone}`);
    }
  }, [session, phone, sendCode.error]);

  const customerPhone = useMemo(() => {
    const queryPhone = searchParams.get('phone');
    if (!queryPhone) return '';
    const formattedPhone = getPhoneNumberFormatted(queryPhone);
    return formattedPhone || queryPhone.substring(queryPhone.length - 8);
  }, [searchParams]);

  console.log({ customerPhone });

  return (
    <div className="flex flex-col gap-4 text-carmine">
      <Heading as="h2">{t('domain.booking.step5.heading')}</Heading>
      <BookingOverview />
      {customerPhone ? (
        <UserForm
          onSubmit={({ name, email, phoneNumber, streetAddress, zipCode, city }) => {
            posthog.capture('Customer Info submitted');
            actions.onStepSubmit({
              name,
              phone: phoneNumber,
              email,
              addres: streetAddress,
              zip: zipCode,
              place: city,
            });
          }}
          defaultValues={{ phoneNumber: customerPhone }}
          submitText={t('domain.booking.step5.cta')}
          clearPhone={() => actions.removeQueryValue('phone')}
          disablePhone
        />
      ) : (
        <div className="pt-4">
          <LoginForm
            ctaText={t('domain.booking.step5.cta')}
            returnTo={asPath}
            hideHeading
            allowGuestSignup
          />
        </div>
      )}
    </div>
  );
};
export default CustomerInfo;
