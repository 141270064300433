import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { useDisclosure } from '@petrus/ui-hooks';
import Icons from '@petrus/ui-icons';
import {
  Dialog,
  Label,
  Btn,
  Button,
  Text,
  CheckboxIndicator,
  SpeiceIcon,
} from '@petrus/ui-library';
import { twMerge } from 'tailwind-merge';
import { Link } from 'common/components/Link';
import { config } from 'config';
import { Treatments } from '../../../graphql/generated/graphql-request';

type Props = {
  onSelect: ({
    service,
    isSelected,
  }: {
    service: Partial<Pick<Treatments, 'provet_id' | 'name'>>;
    isSelected: boolean;
  }) => void;

  selectedServices: string[];
  service: Treatments;
};

const ServiceListItem = ({ onSelect, service, selectedServices }: Props) => {
  const { t } = useTranslation('common');
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isBlockOpen, onToggle: onBlockedToggle } = useDisclosure();
  const disabled = useMemo(() => {
    const isCleaningSelected = selectedServices.find((id) =>
      config.treatmentOverwrite.cleaning.ids.includes(id),
    );

    if (config.treatmentOverwrite.cleaning.ids.includes(service.provet_id)) {
      if (selectedServices.length >= config.treatmentOverwrite.cleaning.limit) {
        return !isCleaningSelected;
      }

      return !selectedServices
        .filter((id) => !config.treatmentOverwrite.cleaning.ids.includes(id))
        .every((id) => config.treatmentOverwrite.cleaning.treatments.includes(id));
    }

    const isCurrentSelected = selectedServices.includes(service.provet_id);

    if (selectedServices.find((id) => config.treatmentOverwrite.cleaning.ids.includes(id))) {
      if (selectedServices.length >= config.treatmentOverwrite.cleaning.limit) {
        return !isCurrentSelected;
      }

      return !config.treatmentOverwrite.cleaning.treatments.includes(service.provet_id);
    }

    return false;
  }, [service, selectedServices]);

  const selected = useMemo(() => {
    return selectedServices.includes(service.provet_id);
  }, [selectedServices, service]);

  const onServiceSelect = useCallback(
    (isSelected: boolean) => {
      const { id, isActive } = config.reseptOverwrite;

      if (isActive && [id].includes(service.provet_id)) {
        return onBlockedToggle();
      }

      return onSelect({ service, isSelected });
    },
    [service, onSelect, onBlockedToggle],
  );

  return (
    <div className="flex justify-between items-center text-space">
      <div className="flex grow items-center">
        <CheckboxIndicator
          id={service.provet_id}
          onChange={onServiceSelect}
          value={selected}
          disabled={disabled}
          className="flex-none"
        />
        <Label
          clickable={!disabled}
          htmlFor={service.provet_id}
          className={twMerge('ml-2 font-light mr-6', disabled && 'text-space/30 line-through')}
          label={service.display_name}
        />
      </div>
      <div className="flex items-center">
        <Text className="mx-2">{t('domain.services.price', { count: service.price })}</Text>
        <Button
          size="micro"
          look="outline"
          className="bg-transparent border-1 border-space"
          onClick={onToggle}
        >
          <Icons.Info className="w-5 h-5 p-0 fill-space" />
        </Button>
      </div>
      <Dialog isOpen={isOpen} onIsOpenChange={onToggle} title={service.name}>
        <Text>{service.description}</Text>
        {service.species && (
          <div className="flex items-center pt-2">
            <Text>
              Obs! Spesifikk behandling for{' '}
              {t(`domain.animal.species.${service.species}`).toLocaleLowerCase()}
            </Text>
            <SpeiceIcon speice={service.species} className="ml-2 w-4 h-4" />
          </div>
        )}
      </Dialog>
      <Dialog isOpen={isBlockOpen} onIsOpenChange={onBlockedToggle} title="Resept">
        <Text size="small" className="block pb-4">
          Har dyret ditt vært innom oss i løpet av de siste{' '}
          <Text as="span" size="small" weight="bold">
            12 månedene
          </Text>{' '}
          kan du enkelt bestille resept via vårt nettskjema. Hvis ikke må du booke en konsultasjon
          så en av våre dyktige, dyreelskende veterinærer kan undersøke ditt dyr først.
        </Text>
        <div className="flex flex-col sm:flex-row justify-end gap-2">
          <Btn
            size="sm"
            intent="simple"
            className="flex"
            onClick={() => {
              const { konsultasjonId, title } = config.reseptOverwrite;
              onSelect({
                service: { provet_id: konsultasjonId, name: title },
                isSelected: !selectedServices.includes(konsultasjonId),
              });
              onBlockedToggle();
            }}
          >
            Bestill konsultasjon
          </Btn>
          <Btn size="sm" className="stroke-white flex gap-2" asChild>
            <Link href={config.reseptOverwrite.link}>
              <span>{config.reseptOverwrite.cta}</span>
              <Icons.Newscreen className="w-4 h-4" />
            </Link>
          </Btn>
        </div>
      </Dialog>
    </div>
  );
};

export { ServiceListItem };
export type { Props };
