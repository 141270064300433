import React, { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import * as yup from 'yup';
import { getPhoneNumberFormatted } from '@askeladden/phonenumber';
import { useYupResolver } from '@petrus/ui-hooks';
import { FormProvider, useForm, Input, Btn, Alert } from '@petrus/ui-library';
import Icons from '@petrus/ui-icons';

import { Generic } from '../../../types';
import {
  name,
  email,
  phone,
  city,
  streetAddress,
  zipCode,
} from '../../../common/helpers/yup/validation';

type FormValues<T extends Generic> = {
  name: string;
  email: string;
  phoneNumber: string;
  zipCode: string;
  streetAddress: string;
  city: string;
} & T;

type Props<T extends Generic> = {
  onSubmit: (values: FormValues<T>) => void;
  submitText: string;
  error?: string;
  loading?: boolean;
  defaultValues?: Partial<Generic>;
  disablePhone?: boolean;
  clearPhone?: () => void;
} & Omit<React.HTMLAttributes<HTMLElement>, 'onSubmit'>;

const schema = yup.object().shape({
  name,
  email,
  phoneNumber: phone,
  streetAddress,
  zipCode,
  city,
});

const UserForm = <T extends Generic>({
  onSubmit,
  submitText,
  error,
  loading = false,
  disablePhone = false,
  clearPhone,
  defaultValues = {
    phoneNumber: '',
    name: '',
    email: '',
    streetAddress: '',
    zipCode: '',
    city: '',
  },
  children = null,
  ...props
}: Props<T>) => {
  const { t } = useTranslation('common');
  const resolver = useYupResolver(schema);
  const methods = useForm({ defaultValues, resolver, mode: 'onChange' });
  const {
    formState: { errors, isValid },
  } = methods;

  const onFormSubmit = useCallback(
    (formValues: FormValues<T>) => {
      const formattedPhoneNumber = getPhoneNumberFormatted(formValues.phoneNumber);

      if (!formattedPhoneNumber) {
        throw new Error('Invalid phone number');
      }

      const values = {
        ...formValues,
        phoneNumber: formattedPhoneNumber.toString(),
      };

      onSubmit(values);
    },
    [onSubmit],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onFormSubmit)} {...props} className="space-y-4 mt-4">
        <Alert type="danger" hasError={!!error}>
          {error}
        </Alert>
        <div className="relative">
          <Input
            id="phoneNumber"
            autoComplete="tel"
            placeholder="+4790xxxxxx"
            disabled={disablePhone}
            error={t(errors.phoneNumber?.message.toString())}
            label={t('domain.user.form.phone')}
          />
          {clearPhone && (
            <Btn
              type="button"
              size="xs"
              onClick={clearPhone}
              intent="unstyled"
              className="absolute bottom-1 right-4 rounded-full border border-transparent hover:boder-gray-600"
            >
              <Icons.Cross className="fill-gray-600 w-4" />
            </Btn>
          )}
        </div>
        <Input
          id="name"
          autoComplete="name"
          placeholder="John Doe"
          error={t(errors.name?.message.toString())}
          label={t('domain.user.form.name')}
        />
        <Input
          id="email"
          autoComplete="email"
          placeholder="john@doe.com"
          error={t(errors.email?.message.toString())}
          label={t('domain.user.form.email')}
        />
        <Input
          id="streetAddress"
          autoComplete="street-address"
          placeholder="Parkveien 12"
          error={t(errors.streetAddress?.message.toString())}
          label={t('domain.user.form.streetAddress')}
        />
        <div className="flex gap-4 justify-evenly">
          <Input
            id="zipCode"
            autoComplete="postal-code"
            placeholder="0350"
            error={t(errors.zipCode?.message.toString())}
            label={t('domain.user.form.zipCode')}
          />
          <Input
            id="city"
            autoComplete="address-level2"
            placeholder="Oslo"
            error={t(errors.city?.message.toString())}
            label={t('domain.user.form.city')}
          />
        </div>
        {children as any}
        <div className="flex justify-end">
          <Btn intent="primary" type="submit" loading={loading} disabled={!isValid}>
            {submitText}
          </Btn>
        </div>
      </form>
    </FormProvider>
  );
};

export { UserForm };
export type { Props, FormValues };
