import { Availablity } from 'domain/availability/types';
import React, { useState, useMemo, useEffect, HTMLAttributes } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@petrus/ui-hooks';

import { config } from 'config';
import { posthog } from 'posthog-js';
import { Genders, Species } from 'types';
import { BookingProviderType, BookingRequestInfo, BookingStepConfig } from '../types';
import { CreateBookingBody } from '../../../common/services/ApiService/types';
import { useSession } from '../../auth/providers/SessionProvider';
import { publicSdk } from '../../../graphql/sdk';
import {
  TreatmentStatusEnum,
  AnimalSpeciesEnum,
  CreateBookingInput,
} from '../../../graphql/generated/graphql-request';
import { bookingStepsGuest, bookingStepsMember } from '../config';

import { addDaysToDate, getClearDate } from '../../../common/utils/date.utils';

const BookingContext = React.createContext({} as BookingProviderType);

export const useBooking = () => React.useContext(BookingContext);

export type Props = HTMLAttributes<HTMLElement>;

const DEFAULT_AVAILABILITY_DAYS = 60;

function getBookingDataFromQuery<T>(query: { [x: string]: any }): Partial<T> {
  const bookingDataFromQuery: any = {};
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      const value = decodeURIComponent(query[key]).replaceAll('+', ' ');

      if (key === 'serviceIds') {
        bookingDataFromQuery[key] = String(value).split(',');
      } else if (key === 'phone') {
        bookingDataFromQuery[key] = decodeURIComponent(query[key]);
      } else {
        bookingDataFromQuery[key] = value;
      }
    }
  });
  return bookingDataFromQuery as Partial<T>;
}

function determineStepIndex({
  bookingData,
  isAuthenticated,
}: {
  bookingData: Partial<BookingRequestInfo>;
  isAuthenticated: boolean;
}) {
  if (!bookingData) {
    return 0;
  }
  const bookingSteps = isAuthenticated ? bookingStepsMember : bookingStepsGuest;

  for (let i = 0; i < bookingSteps.length; i++) {
    if (
      !bookingSteps[i].queryValues?.some((values) =>
        values?.every((value) => !!bookingData?.[value]),
      )
    ) {
      return i;
    }
  }

  return bookingSteps.length - 1;
}

function getQueryStringParams(url: string): Record<string, string> {
  try {
    const splitted = url.split('?');
    if (splitted.length !== 2) {
      return {};
    }
    const query = splitted[1];
    return JSON.parse('{"' + decodeURI(query.replace(/&/g, '","').replace(/=/g, '":"')) + '"}');
  } catch (e) {
    return {};
  }
}

function queryFromBookingData(bookingData: any) {
  return Object.keys(bookingData).reduce((acc, key) => {
    if (bookingData[key]) {
      if (key === 'serviceIds') {
        acc[key] = bookingData[key].toString();
      } else {
        acc[key] = bookingData[key];
      }
    }
    return acc;
  }, {} as any);
}

export const BookingProvider = ({ children }: Props) => {
  const router = useRouter();
  const { sdk, session } = useSession();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [bookingData, setBookingData] = useState<Partial<BookingRequestInfo>>({});
  const [serviceGroups, setServiceGroups] = useState([]);
  const [locations, setLocations] = useState([]);
  const [availability, setAvailability] = useState([]);

  const bookingSteps = useMemo(
    () => (!session ? bookingStepsGuest : bookingStepsMember),
    [session],
  );
  const currentStep = useMemo(() => bookingSteps[currentIndex], [bookingSteps, currentIndex]);

  useEffect(() => {
    setAvailability([]);
    getLocations();
  }, [JSON.stringify(bookingData.serviceIds)]);

  const serviceGroupsParams = useQuery();
  const locationsParams = useQuery();
  const availabilityParams = useQuery();
  const bookingParams = useQuery();

  function setDataFromPath(path: string) {
    const query = getQueryStringParams(path);

    const bookingDataFromQuery = getBookingDataFromQuery<BookingRequestInfo>(query);
    setBookingData(bookingDataFromQuery);

    const determinedIndex = determineStepIndex({
      bookingData: bookingDataFromQuery,
      isAuthenticated: !!session,
    });
    setCurrentIndex(determinedIndex);
  }

  useEffect(() => {
    if (router.isReady) {
      setDataFromPath(router.asPath);
    }
  }, [router.isReady]);

  useEffect(() => {
    const query = getQueryStringParams(router.asPath);
    const bookingDataFromQuery = getBookingDataFromQuery<BookingRequestInfo>(query);
    const determinedIndex = determineStepIndex({
      bookingData: bookingDataFromQuery,
      isAuthenticated: !!session,
    });
    if (determinedIndex !== currentIndex) {
      setBookingData(bookingDataFromQuery);
      setCurrentIndex(determinedIndex);
    }
  }, [router.query]);

  useEffect(() => {
    if (Object.keys(bookingData).length !== 0) {
      const query = queryFromBookingData(bookingData);
      router.push({ pathname: router.pathname, query }, undefined, { shallow: true });
    }
  }, [bookingData]);

  useEffect(() => {
    posthog.capture(`bookingstep_${currentStep.step}`, { authenticated: !!session });
  }, [currentStep]);

  const removeStepQuery = (index: number) => {
    const newBookingData = { ...bookingData };

    const removeStepValues = (step: BookingStepConfig) => {
      step.queryValues?.forEach((key) => {
        key.forEach((nestedKey) => {
          delete newBookingData[nestedKey.toString()];
        });
      });
      step.customDeleteValues?.forEach((key) => delete newBookingData[key.toString()]);
    };

    removeStepValues(bookingSteps[index]);
    updateBookingStep(newBookingData);
  };

  const removeQueryValue = (value: keyof BookingRequestInfo) =>
    setBookingData((prevData) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
      const { [value]: deletedValue, ...newBookingData } = prevData;
      return newBookingData;
    });

  const onStepSubmit = (newData: Partial<BookingRequestInfo>) => {
    const updatedData = { ...bookingData, ...newData };
    updateBookingStep(updatedData);
  };

  const updateBookingStep = (updatedData: Partial<BookingRequestInfo>) => {
    setBookingData(updatedData);
    setCurrentIndex(
      determineStepIndex({
        bookingData: updatedData,
        isAuthenticated: !!session,
      }),
    );
  };

  const onPreviousButtonClick = () => {
    removeStepQuery(currentIndex - 1);
  };

  const getServiceGroups = () =>
    serviceGroupsParams.runQuery(async () => {
      const { petType } = bookingData;
      if (!petType) return; // TODO: allow optional petGender filter { gender: { _eq: petGender as AnimalGendersEnum } }
      const response = await publicSdk.getTreatmentsByGroup({
        where: {
          _and: [{ status: { _eq: TreatmentStatusEnum.PUBLISHED } }],
          _or: [
            { species: { _eq: petType as AnimalSpeciesEnum } },
            {
              _and: [
                { species: { _eq: petType as AnimalSpeciesEnum } },
                { gender: { _is_null: true } },
              ],
            },
            { _and: [{ gender: { _is_null: true } }, { species: { _is_null: true } }] },
          ],
        },
      });

      setServiceGroups(response.treatment_groups);
    });

  const getLocations = () =>
    locationsParams.runQuery(async () => {
      if (!bookingData.serviceIds) return;
      const toothCleanIsDefined = bookingData.serviceIds.some((id) =>
        config.treatmentOverwrite.cleaning.ids.includes(id),
      );
      const servicesToCheck = toothCleanIsDefined
        ? bookingData.serviceIds.filter((id) => config.treatmentOverwrite.cleaning.ids.includes(id))
        : bookingData.serviceIds;

      const { locations: newLocations } = await publicSdk.getLocations({
        where: {
          opening_date: {
            _lte: new Date().toISOString(),
          },
          _and: servicesToCheck.map((id) => ({
            shift_types: {
              treatment_shift_types: {
                treatment: {
                  provet_id: { _eq: id },
                },
              },
            },
          })),
        },
      });
      setLocations(newLocations);

      newLocations.map((location) => getAvailability(location.id.toString()));
    });

  const getAvailability = (locationId?: string) =>
    availabilityParams.runQuery(async () => {
      const availabilityLocationId = locationId || bookingData.locationId;
      if (!availabilityLocationId) return;
      const toothCleanIsDefined = bookingData.serviceIds.some((id) =>
        config.treatmentOverwrite.cleaning.ids.includes(id),
      );
      const servicesToCheck = toothCleanIsDefined
        ? bookingData.serviceIds.filter((id) => config.treatmentOverwrite.cleaning.ids.includes(id))
        : bookingData.serviceIds;

      const startDate = getClearDate().toISOString();
      const endDate = addDaysToDate(DEFAULT_AVAILABILITY_DAYS);

      const { getAvailability: newAvailability } = await publicSdk.getAvailability({
        locationId: availabilityLocationId,
        serviceIds: servicesToCheck,
        startDate,
        endDate,
      });

      setAvailability((prevState) => [
        ...prevState,
        {
          locationId: availabilityLocationId,
          availability: newAvailability as Availablity[],
        },
      ]);
    });

  const createBooking = ({
    note,
    ...petInfo
  }:
    | Partial<CreateBookingBody['pet'] & { note: CreateBookingBody['note'] }>
    | { note: CreateBookingBody['note'] }) => {
    let updatedBookingdata = { ...bookingData };
    if (petInfo && Object.keys(petInfo).length > 0) {
      const { name, type, gender, dob } = petInfo as Partial<CreateBookingBody['pet']>;
      updatedBookingdata = {
        ...bookingData,
        petName: name,
        petType: type as Species,
        petGender: gender as Genders,
        petDob: dob,
      };
    }
    const memberBooking = !!bookingData.userId;

    posthog.capture('book class pressed', { memberBooking });
    const bookingBody: Partial<CreateBookingInput> = {
      locationId: bookingData.locationId,
      serviceIds: bookingData.serviceIds,
      time: bookingData.time,
      note,
    };
    if (memberBooking) {
      Object.assign(bookingBody, { userId: bookingData.userId });

      if (bookingData.petId) {
        Object.assign(bookingBody, { petId: bookingData.petId });
      } else {
        Object.assign(bookingBody, {
          pet: {
            name: bookingData.petName,
            gender: bookingData.petGender,
            type: bookingData.petType,
            weight: parseFloat(String(bookingData.petWeight).replace(/,/g, '.')),
            dob: bookingData.petDob,
          },
        });
      }
    } else {
      Object.assign(bookingBody, {
        user: {
          name: bookingData.name,
          phoneNumber: bookingData.phone,
          email: bookingData.email,
          streetAddress: bookingData.addres,
          zipCode: bookingData.zip,
          city: bookingData.place,
        },
        pet: petInfo,
      });
    }

    bookingParams
      .runQuery(async () => {
        await (sdk ?? publicSdk).createBooking({ args: bookingBody as CreateBookingInput });
        setBookingData({ ...updatedBookingdata, complete: true });
      })
      .then(() => posthog.capture('booking complete', { memberBooking, ...bookingBody }));
  };

  return (
    <BookingContext.Provider
      value={{
        isReady: router.isReady,
        currentStep,
        data: {
          currentIndex,
          bookingSteps,
          serviceGroups,
          locations,
          availability,
          meta: {
            serviceGroups: serviceGroupsParams,
            locations: locationsParams,
            availability: availabilityParams,
            booking: bookingParams,
          },
        },
        body: bookingData,
        actions: {
          onPreviousButtonClick,
          removeQueryValue,
          onStepSubmit,
          getServiceGroups,
          getLocations,
          getAvailability,
          createBooking,
        },
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};
