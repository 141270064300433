import { Text } from '@petrus/ui-library';
import React from 'react';
import Icons from '@petrus/ui-icons';
import { useTranslation } from 'next-i18next';

function LocationClosedIndicator() {
  const { t } = useTranslation();

  return (
    <div className="w-fit mt-2 py-3 px-2 bg-beige rounded-lg flex gap-1 justify-center items-start">
      <Icons.InfoCircle className="w-8 p-0" />
      <Text className="mt-0.5" size="tiny">
        {t('domain.clinics.closed')}
      </Text>
    </div>
  );
}

export default LocationClosedIndicator;
