/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useRouter } from 'next/router';
import { Container } from '@petrus/ui-library';

import { PageBackbone } from '../../common/components/PageBackbone';
import { BookingWizard } from '../../domain/bookings/containers/BookingWizard';
import { BookingProvider } from '../../domain/bookings/providers/BookingProvider';

const Page = () => {
  const router = useRouter();

  return (
    <PageBackbone title="Booking" description="Book an appointment" path={router.asPath}>
      <Container size="md" className="w-full">
        <BookingProvider>
          <BookingWizard />
        </BookingProvider>
      </Container>
    </PageBackbone>
  );
};

export default Page;
