import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { Text } from '@petrus/ui-library';

import { Link } from 'common/components/Link';
import { routes } from 'config';

type Props = React.HTMLAttributes<HTMLSpanElement>;

const LoginLink = ({ className, ...props }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Text className={classNames('block', className)} {...props}>
      {t('domain.user.loginText')} <Link href={routes.login}>{t('domain.user.loginCta')}</Link>
    </Text>
  );
};

export { LoginLink };
