import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import * as yup from 'yup';
import { useYupResolver } from '@petrus/ui-hooks';
import { FormProvider, useForm, Input, Button, Alert } from '@petrus/ui-library';

import { phone } from 'common/helpers/yup/validation';
import { useSession } from '../providers/SessionProvider';

type FormValues = {
  phone: string;
};

type Props = {
  submitText?: string;
  defaultValues?: FormValues;
  allowGuestSignup?: boolean;
};

const schema = yup.object().shape({ phone });

const PhoneNumberForm = ({ submitText, defaultValues, allowGuestSignup }: Props) => {
  const { t } = useTranslation('common');
  const resolver = useYupResolver(schema);
  const methods = useForm({ defaultValues, resolver, mode: 'onChange' });
  const {
    formState: { errors },
  } = methods;
  const { sendCode } = useSession();

  const onSubmit = useCallback(({ phone }: FormValues) => sendCode.mutate(phone), []);

  const hasError = useMemo(() => {
    if (allowGuestSignup)
      return sendCode.isError && (sendCode.error as any).message !== 'no_signup';
    return sendCode.isError;
  }, [allowGuestSignup, sendCode.isError, sendCode.error]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-4">
        <Alert hasError={hasError} type="danger">
          {sendCode.error ? t(`errors.phone.${(sendCode.error as any).message}`) : null}
        </Alert>
        <Input
          id="phone"
          type="tel"
          autoComplete="tel"
          placeholder="+4790xxxxxx"
          error={t(errors.phone?.message.toString())}
          label={t('domain.auth.login.label')}
        />
        <Button
          disabled={!methods.formState.isValid}
          block
          loading={sendCode.isLoading}
          type="submit"
        >
          {submitText}
        </Button>
      </form>
    </FormProvider>
  );
};

export { PhoneNumberForm, schema };
export type { Props, FormValues };
