import React from 'react';
import { useTranslation } from 'next-i18next';
import { Heading, Text } from '@petrus/ui-library';
import { useSession } from '../providers/SessionProvider';
import { PhoneNumberForm } from './PhoneNumberForm';
import { OTPForm } from './OTPForm';

type Props = {
  startHeading?: string;
  startSubHeading?: string;
  ctaText?: string;
  hideHeading?: boolean;
  returnTo?: string;
  allowGuestSignup?: boolean;
} & React.HTMLAttributes<HTMLElement>;

const LoginForm = ({
  startHeading,
  startSubHeading,
  ctaText,
  hideHeading = false,
  returnTo,
  children,
  allowGuestSignup = false,
}: Props) => {
  const { t } = useTranslation('common');
  const { phone, sendCode } = useSession();

  if (!phone || sendCode.isLoading || sendCode.isError) {
    return (
      <>
        {!hideHeading && startHeading && <Heading as="h2">{startHeading}</Heading>}
        {startSubHeading && <Text>{startSubHeading}</Text>}
        <PhoneNumberForm
          submitText={ctaText || t('domain.auth.login.cta')}
          allowGuestSignup={allowGuestSignup}
        />
        {children}
      </>
    );
  }

  return (
    <>
      {!hideHeading && <Heading as="h2">{t('domain.auth.otp.heading')}</Heading>}
      <OTPForm submitText={t('domain.auth.otp.cta')} returnTo={returnTo} />
    </>
  );
};

export { LoginForm };
export type { Props };
