import { BookingStepConfig } from './types';

// defines the step and which query params needs to be present in order to continue from step. The different query arrays represents the values needed signed in or not
export const bookingStepsGuest: BookingStepConfig[] = [
  {
    step: 'animal',
    queryValues: [['petType']],
  },
  {
    step: 'treatment',
    queryValues: [['serviceIds']],
  },
  {
    step: 'location',
    queryValues: [['locationId']],
  },
  {
    step: 'time',
    queryValues: [['time']],
    customDeleteValues: ['phone'],
  },
  {
    step: 'customerInfo',
    queryValues: [['name', 'email', 'phone', 'addres', 'zip', 'place']],
  },
  {
    step: 'animalInfo',
    queryValues: [['petGender', 'petName', 'petDob']], // TODO: handle back-navigation
  },
];

export const bookingStepsMember: BookingStepConfig[] = [
  {
    step: 'animal',
    queryValues: [['petId'], ['petType']],
  },
  {
    step: 'treatment',
    queryValues: [['serviceIds']],
  },
  {
    step: 'location',
    queryValues: [['locationId']],
  },
  {
    step: 'time',
    queryValues: [['time']],
  },
  {
    step: 'confirmation',
    queryValues: [[]],
  },
];
