import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import Icons from '@petrus/ui-icons';
import { Progress, Text, Button } from '@petrus/ui-library';

import * as numberUtils from '../../../common/utils/number.utils';

type Props = {
  numberOfSteps: number;
  currentIndex: number;
  goBack: () => void;
};

const Stepper = ({ numberOfSteps, currentIndex, goBack }: Props) => {
  const { t } = useTranslation('common');
  const progress = useMemo(
    () => numberUtils.getPercetangeOf(currentIndex + 1, numberOfSteps) - 10,
    [currentIndex, numberOfSteps],
  );

  const hasPreviousStep = useMemo(() => currentIndex > 0, [currentIndex]);

  return (
    <div className="flex items-center">
      {hasPreviousStep && (
        <Button
          size="micro"
          look="focus"
          className="group mr-3 flex-1 text-carmine px-1 rounded-full"
          onClick={goBack}
        >
          <Icons.DogBoneArrow
            label="back"
            className="group-hover:-translate-x-1 transition-all duration-300 inline mr-1 fill-carmine h-6"
          />
          <Text className="hidden md:inline">{t('goBack')}</Text>
        </Button>
      )}
      <div className="w-full grow py-2">
        <Progress progress={progress} />
      </div>
    </div>
  );
};

export { Stepper };
export type { Props };
