import React, { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { Button, Text } from '@petrus/ui-library';

import * as dateUtils from 'common/utils/date.utils';

type Props = {
  sendTime: string;
  onResendRequest: () => void;
  countdownSecond?: number;
  loading?: boolean;
};

const ResendTimer = ({
  onResendRequest,
  sendTime,
  countdownSecond = 20,
  loading = false,
}: Props) => {
  const [counter, setCounter] = useState<undefined | number>();
  const { t } = useTranslation('common');

  useEffect(() => {
    const runCounter = () => {
      const timeTilResendIsAllowed = dateUtils.getResendTimeDiff(sendTime, countdownSecond);

      if (timeTilResendIsAllowed >= 0) {
        setCounter(timeTilResendIsAllowed);
      }
    };

    const timer = setInterval(runCounter, 1000);

    return () => clearInterval(timer);
  }, [sendTime]);

  const disabled = counter === undefined || counter > 0;

  return (
    <div className="pb-4">
      <Text size="small" className="block">
        {t('domain.auth.resend.text')}
        <Button
          type="button"
          onClick={onResendRequest}
          disabled={disabled}
          className="inline p-0 mx-1 border-b-1 border-pink hover:border-transparent"
          size="micro"
          look="unstyled"
          loading={loading}
          title={
            disabled && counter
              ? t('domain.auth.resend.timer', { count: counter })
              : 'send ny engangskode'
          }
        >
          <Text size="small">{t('domain.auth.resend.cta')}</Text>
        </Button>
      </Text>
    </div>
  );
};

export { ResendTimer };
export type { Props };
