import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { CardButton, Card } from '@petrus/ui-library';
import { AnimalFragment } from 'graphql/generated/graphql-request';

import { AnimalInfo } from './AnimalInfo';

type Props = {
  animal: AnimalFragment;
  selected: boolean;
  onClick: (a: AnimalFragment) => void;
};

const AnimalCardButtonComponent = ({ animal, onClick, selected }: Props) => {
  const onAnimalClick = useCallback(() => onClick(animal), [animal, onClick]);
  const disabled = useMemo(() => animal.state !== 'active', [animal]);

  return (
    <CardButton
      disabled={disabled}
      selected={selected}
      onClick={onAnimalClick}
      className={classNames('w-full', !disabled && 'hover:shadow-md')}
      useArrow
    >
      <AnimalInfo animal={animal} />
    </CardButton>
  );
};

const Skeleton = () => (
  <Card className="w-full">
    <AnimalInfo.Skeleton />
  </Card>
);

const AnimalCardButton = Object.assign(AnimalCardButtonComponent, {
  Skeleton,
});

export { AnimalCardButton };
export type { Props };
